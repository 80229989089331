.message.gdpr-cookie {
    padding: 15px;
    background: $brand__block__background;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;

    .content {
        max-width: 1240px;
        margin: 0 auto;
        text-align: center;

        @include min-screen($screen__s) {
            display: flex;
            align-items: center;
            text-align: left;
        }

        p {
            flex-grow: 1;

            strong {
                display: block;
            }
        }

        .actions {
            flex-shrink: 0;
            padding-top: 0.5em;

            @include min-screen($screen__s) {
                margin-left: 15px;
                padding-top: 0;
            }
        }
    }
}
